<template>
  <div>
    <!-- eslint-disable max-len -->
    <label class="form-label" v-if="showLabel" :for="label" >{{label}}</label>
    <div :class="[{ 'has-icon': inputIconName }, classes]">
      <div class="input-control">
        <div class="input-icon" v-if="inputIconName === 'input-email'">
          <UserIcon size="24"/>
        </div>
        <div class="input-icon" v-else-if="inputIconName === 'input-password'">
          <LockClosedIcon size="24"/>
        </div>
        <input
          :id="label"
          :disabled="disabled"
          :type="type"
          :placeholder="placeholder"
          :class="[{'error-input': errorMessage}, 'input-field']"
          v-model="model"
        >
        <div class="input-icon-group hidden">
          <button class="button p-0 h-8 w-8 border-transparent rounded-full hover:border-transparent hover:bg-transparent hover:text-primary-700 hover:shadow-none">
            <XCircleIcon size="24"/>
          </button>
        </div>
      </div>
    </div>
    <div v-if="errorMessage" class="text-red-600 text-sm mt-1">
      {{errorMessage}}
    </div>
  </div>
</template>

<script>
// import Icon from '@/components/ui/AppIcon.vue';
import { UserIcon, LockClosedIcon, XCircleIcon } from '@vue-hero-icons/outline';

export default {
  name: 'app-input',
  data: () => ({
    model: '',
  }),
  components: {
    UserIcon,
    XCircleIcon,
    LockClosedIcon,
  },
  props: {
    value: {},
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: 'input-group',
    },
    errorMessage: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputIconName: {
      type: String,
      default: '',
    },
    emitLabelClick: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    model(oldValue) {
      this.$emit('input', oldValue);
    },
  },
  methods: {
    clickIcon(evt) {
      if (this.emitLabelClick) this.$emit('clickIcon', evt);
    },
  },
  mounted() {
    this.model = this.value;
  },
};
</script>

<style lang="scss" scoped>
  .label-icon {
    margin-left: 4px;
  }

  .cursor {
    cursor: pointer;
  }

  .input-wrapper-with-icon {
    position: relative;

    img {
      position: absolute;
      left: 16px;
    }

    input {
      padding-left: 40px;
    }
  }
</style>

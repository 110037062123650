<template>
  <div>
    <Loader :loading="loading" />

    <div
      v-show="!loading"
      class="hero-pattern min-h-screen flex flex-col justify-between items-center"
    >
      <parcelex-business-header />
      <div class="flex-shrink-0 p-6 lg:p-0 w-full md:max-w-md">
        <div class="p-6 lg:p-9 lg:pb-8 bg-white rounded-2xl mb-6 shadow-lg">
          <form
            v-on:submit.prevent="handleRecoverPassword"
            class="login-wrapper"
            :class="{ mobile }"
          >
            <header class="items-center flex-col flex">
              <div class="flex-shrink-0 mb-6">
                <img class="w-btn mr-3" src="brand-icon-lock.svg" />
              </div>
              <h1 class="font-bold text-lg mb-6">Recuperar senha</h1>
              <p class="text-gray-500 mb-8 text-center">
                Enviaremos para seu email da conta, instruções para você cadastrar uma nova senha.
              </p>
            </header>

            <Input
              label="Email"
              class="mb-5"
              :class="[{ mobile }, { error: getMessage('email') }]"
              @input="formHandler('email', ...arguments)"
              :errorMessage="getMessage('email')"
            />

            <Button class="button button-primary w-full mb-6">
              Enviar
            </Button>
          </form>
        </div>
        <div class="text-center">
          <a href="javascript:;" class="text-white hover:underline" @click="goToLogin">
            Voltar para o login
          </a>
        </div>
      </div>
      <footer class="text-center text-primary-500 pt-4 pb-6">
        &copy; Parcelex - {{ actualDate }}
      </footer>
    </div>
  </div>

</template>

<script>
import { mapActions } from 'vuex';
import Input from '@/components/ui/AppInput.vue';
import Button from '@/components/ui/AppButton.vue';
import UtilMixin from '@/mixins/util';
import Loader from '@/components/common/Loader.vue';
import ParcelexBusinessHeader from '../../../components/common/ParcelexBusinessHeader.vue';

export default {
  name: 'login',
  metaInfo: {
    title: 'Para Negócios',
  },
  data: () => ({
    loading: false,
    mobile: false,
    formInputs: {
      email: {
        currentValue: undefined,
        isValid: false,
        validateFunction: 'isValidEmail',
        errorMessage: 'Email inválido',
        currentMessage: '',
      },
    },
  }),
  mixins: [UtilMixin],
  components: {
    Input,
    Button,
    ParcelexBusinessHeader,
    Loader,
  },
  computed: {
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    actualDate() {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions('auth', ['recoverPassword']),
    async handleRecoverPassword() {
      if (this.isValidForm) {
        this.loading = true;
        const { error } = await this.recoverPassword({
          email: this.formInputs.email.currentValue,
        });

        if (error) {
          if (error.message === 'User not found') {
            this.formInputs.email.currentMessage = 'Usuário não encontrado';
          } else {
            this.formInputs.email.currentMessage = 'Ocorreu um erro inesperado, entre em contato';
          }
        }

        this.loading = false;
      } else {
        this.formInputs.email.currentMessage = 'Informe um email válido';
      }
    },
    formHandler(type, value) {
      if (!value) return;

      const { validateFunction } = this.formInputs[type];
      this.formInputs[type].isValid = this[validateFunction](value);
      this.formInputs[type].currentValue = value;

      if (this.formInputs[type].currentMessage !== '') {
        this.formInputs[type].currentMessage = '';
      }
    },
    getMessage(type) {
      return this.formInputs[type].currentMessage;
    },
    goToLogin() {
      this.$router.push('/login');
    },
  },
  mounted() {
    this.mobile = this.isMobile();
  },
};
</script>

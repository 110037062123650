<template>
  <button
    :disabled="disabled"
    @click="onClick">
    {{slotAfter ? value : ''}}
    <slot />
    {{!slotAfter ? value : ''}}
  </button>
</template>
<script>
export default {
  name: 'app-button',
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    slotAfter: {
      type: Boolean,
    },
    href: {
      type: String,
    },
  },
  methods: {
    onClick(value) {
      if (this.href) {
        window.location.href = this.href;
      }
      this.$emit('click', value);
    },
  },
};
</script>
